import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import DecisionButtons from '../../../components/DecisionButtons';

import { dateUtcFormat } from '../../../bi/utils/formatDate';
import { routeTaxi } from '../../../bi/utils/trip';

import { DECISIONBUTTONS } from '../../../bi/constants/decisionButtons';
import COMPONENTS from '../../../bi/constants/components';
import { DIALOGS, DIALOGSTITLES } from '../../../bi/constants/trips';
import { ACTION_IN_DATE_TIME_PATTERN } from '../../../constants/time';

import styles from '../styles/trip.module.scss';

class DeleteItemDialogs extends Component {
  static propTypes = {
    activeDialog: PropTypes.string.isRequired,
    trip: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    tripService: PropTypes.object.isRequired,
    closingDocuments: PropTypes.array.isRequired,
    moveToCompany: PropTypes.func.isRequired,
    operationsInf: PropTypes.array.isRequired,
    deleteTrip: PropTypes.func.isRequired,
    deleteOrders: PropTypes.func.isRequired,
    deleteActualVersion: PropTypes.func.isRequired,
    deleteOperations: PropTypes.func.isRequired,
    disableActive: PropTypes.func.isRequired,
    waitResDelete: PropTypes.bool.isRequired,
    disabledButton: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      cannotDeleteTripDialog: false,
      dangerousDeleteTripDialog: false,
      dangerousDeleteTripOnlyIntegration: false,
      dangerousDeleteTripWithIntegration: false,
      confirmDeleteTripDialog: false,
      tripDeletedDialog: false,
      cannotDeleteOrdersDialog: false,
      dangerousDeleteOrdersDialog: false,
      dangerousDeleteOrdersOnlyIntegration: false,
      dangerousDeleteOrdersWithIntegration: false,
      confirmDeleteOrdersDialog: false,
      ordersDeletedDialog: false,
      confirmDeleteActualVersionDialog: false,
      confirmDeleteOperationsDialog: false,
      dangerousDeleteActualVersionDialog: false,
      dangerousActualVersionWithIntegration: false,
      dangerousActualVersionOnlyIntegration: false,
      cannotDeleteActualVersionDialog: false,
      actualversionDeletedDialog: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const resetState = (active) => {
      const resetedState = {};

      for (let key in this.state) {
        resetedState[key] = false;
      }

      resetedState[active] = true;

      this.setState(resetedState);
    };

    if (nextProps.activeDialog === '') {
      resetState();
    } else if (!(nextProps.activeDialog in this.state)) {
      // ничего не делаем
    } else {
      resetState(nextProps.activeDialog);
    }
  }

  closeDialog = (dialogField) => {
    this.setState({ [dialogField]: false });

    this.props.disableActive();
  };

  closeTripDeletedDialog = () => {
    const { trip, companyId } = this.props;

    this.props.moveToCompany(trip.AccountId, companyId);
  };

  closeOrdersDeletedDialog = () => {
    const { trip } = this.props;

    this.props.tripService.load(trip.AccountId, trip.TripId).then(() => {
      this.setState({ ordersDeletedDialog: false });

      this.props.disableActive();
    });
  };

  closeActualVersionDeletedDialog = () => {
    const { trip } = this.props;

    this.props.tripService.load(trip.AccountId, trip.TripId).then(() => {
      this.setState({ actualversionDeletedDialog: false });

      this.props.disableActive();
    });
  };

  renderOperationsInf = () => this.props.operationsInf.map((operation) => {
    const {
      Id, EventDate, Route,
    } = operation;
    const [departure, arrival] = Route;

    const departurePlaceHtml = routeTaxi(departure);
    const arrivalPlaceHtml = routeTaxi(arrival);

    return (
      <div className={ styles['inf-operation'] } key={ Id }>
        <div>
          { DIALOGSTITLES.TAXI_INFO.ID }
          :
          { ' ' }
          { Id }
        </div>
        <div className={ styles['inf-field'] }>
          { DIALOGSTITLES.TAXI_INFO.FROM }
          :
          { ' ' }
          { departurePlaceHtml }
        </div>
        <div className={ styles['inf-field'] }>
          { DIALOGSTITLES.TAXI_INFO.TO }
          :
          { ' ' }
          { arrivalPlaceHtml }
        </div>
        <div className={ styles['inf-field'] }>
          { DIALOGSTITLES.TAXI_INFO.DATE }
          :
          { ' ' }
          { `${dateUtcFormat(EventDate, ACTION_IN_DATE_TIME_PATTERN)}` }
        </div>
      </div>
    );
  });

  renderDialog = (dialogField, mainText, onClick, text, closingDocumentsHtml = null) => {
    const { waitResDelete, disabledButton } = this.props;

    return (
      <Dialog onClick={ () => this.closeDialog(dialogField) }>
        <div className={ styles['user-dialog'] }>
          <div className={ styles['closing-list'] }>
            <div>{ mainText }</div>
            <br />
            { closingDocumentsHtml }
            { text }
          </div>
          <div className={ `${styles.row} ${styles.right}` }>
            <DecisionButtons
              waitingResponse={ waitResDelete }
              onCancel={ () => this.closeDialog(dialogField) }
              onSave={ onClick }
              labelSave={ DECISIONBUTTONS.LABELS.DELETEANYWAY }
              disabled={ disabledButton }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  renderDeleteDialog = (deleteStylesRules, deleteHtmlRules, deleteLabelSaveRules, ruleForDeleteItems) => {
    const { waitResDelete, deleteTrip } = this.props;
    const buttons = ruleForDeleteItems
      ? (
        <div className={ styles.button_delete }>
          <Button
            label={ deleteLabelSaveRules }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ deleteTrip }
          />
        </div>
      )
      : (
        <div className={ `${styles.row} ${styles.right}` }>
          <DecisionButtons
            waitingResponse={ waitResDelete }
            onCancel={ () => this.closeDialog(DIALOGS.DANGEROUS_DELETE.CONFIRM_DELETE_TRIP_DIALOG) }
            onSave={ deleteTrip }
            labelSave={ deleteLabelSaveRules }
          />
        </div>
      );

    return (
      <Dialog
        onClick={ () => this.closeDialog(DIALOGS.DANGEROUS_DELETE.CONFIRM_DELETE_TRIP_DIALOG) }
        showCloseButton={ ruleForDeleteItems }
      >
        <div className={ deleteStylesRules }>
          { deleteHtmlRules }
          { buttons }
        </div>
      </Dialog>
    );
  };

  render() {
    const {
      cannotDeleteTripDialog,
      dangerousDeleteTripDialog,
      dangerousDeleteTripOnlyIntegration,
      dangerousDeleteTripWithIntegration,
      confirmDeleteTripDialog,
      tripDeletedDialog,
      cannotDeleteOrdersDialog,
      dangerousDeleteOrdersDialog,
      dangerousDeleteOrdersOnlyIntegration,
      dangerousDeleteOrdersWithIntegration,
      confirmDeleteOrdersDialog,
      ordersDeletedDialog,
      cannotDeleteActualVersionDialog,
      dangerousDeleteActualVersionDialog,
      dangerousActualVersionWithIntegration,
      dangerousActualVersionOnlyIntegration,
      confirmDeleteActualVersionDialog,
      confirmDeleteOperationsDialog,
      actualversionDeletedDialog,
    } = this.state;

    const {
      waitResDelete,
      closingDocuments,
      deleteTrip,
      deleteOrders,
      deleteActualVersion,
      deleteOperations,
      disabledButton,
      trip: { OrderItems = [] },
    } = this.props;
    const ruleForDeleteItems = OrderItems.length > 1;
    const deleteHtmlDangerous = (
      <div className={ styles.text_dangerous }>
        { DIALOGSTITLES.ATTENTION.OTHER_SERVICES_ON_TRIP }
        <div>
          { DIALOGSTITLES.DELETE.SURE_DELETE }
        </div>
      </div>
    );
    const mappingDocuments = () => closingDocuments.map((item, idx) => <div key={ idx }>{ item }</div>);

    const dangerousDeleteTripHtml = dangerousDeleteTripDialog && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.TRIP_DIALOG,
      DIALOGSTITLES.DELETE_NOT_POSSIBLE.BECOUSE_DOCS.TRIP,
      deleteTrip,
      mappingDocuments(),
    );

    const dangerousDeleteOrdersHtml = dangerousDeleteOrdersDialog && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.ORDERS_DIALOG,
      DIALOGSTITLES.DELETE_NOT_POSSIBLE.BECOUSE_DOCS.ORDERS,
      deleteOrders,
      mappingDocuments(),
    );

    const dangerousDeleteOrdersWithIntegrationHtml = dangerousDeleteOrdersWithIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.ORDERS_DIALOG_WITH_INTEGRATION,
      DIALOGSTITLES.DELETE_NOT_POSSIBLE.BECOUSE_DOCS.ORDERS,
      deleteOrders,
      DIALOGSTITLES.TRIPATTENTION1C,
      mappingDocuments(),
    );

    const dangerousDeleteOrdersOnlyIntegrationHtml = dangerousDeleteOrdersOnlyIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.ORDERS_DIALOG_ONLY_INTEGRATION,
      DIALOGSTITLES.TRIPATTENTION1C,
      deleteOrders,
    );

    const dangerousDeleteActualVersionDialogHtml = dangerousDeleteActualVersionDialog && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.ACTUAL_VERSION_DIALOG,
      DIALOGSTITLES.DELETE_NOT_POSSIBLE.BECOUSE_DOCS.VERSION,
      deleteActualVersion,
      mappingDocuments(),
    );

    const dangerousActualVersionWithIntegrationHtml = dangerousActualVersionWithIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.ACTUAL_VERSION_WITH_INTEGRATION,
      DIALOGSTITLES.DELETE_NOT_POSSIBLE.BECOUSE_DOCS.VERSION,
      deleteActualVersion,
      DIALOGSTITLES.TRIPATTENTION1C,
      mappingDocuments(),
    );

    const dangerousActualVersionOnlyIntegrationHtml = dangerousActualVersionOnlyIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.ACTUAL_VERSION_ONLY_INTEGRATION,
      DIALOGSTITLES.TRIPATTENTION1C,
      deleteActualVersion,
    );

    const dangerousDeleteTripOnlyIntegrationHtml = dangerousDeleteTripOnlyIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.TRIP_DIALOG_ONLY_INTEGRATION,
      DIALOGSTITLES.TRIPATTENTION1C,
      deleteTrip,
    );

    const dangerousDeleteTripWithIntegrationHtml = dangerousDeleteTripWithIntegration && this.renderDialog(
      DIALOGS.DANGEROUS_DELETE.TRIP_DIALOG_WITH_INTEGRATION,
      DIALOGSTITLES.DELETE_NOT_POSSIBLE.BECOUSE_DOCS.TRIP,
      deleteTrip,
      DIALOGSTITLES.TRIPATTENTION1C,
      mappingDocuments(),
    );

    const confirmDeleteRuleHtml = confirmDeleteTripDialog && ruleForDeleteItems && this.renderDeleteDialog(
      `${styles['user-dialog']} ${styles.dangerous_delete}`,
      deleteHtmlDangerous,
      DECISIONBUTTONS.LABELS.DELETE_ITEMS,
      ruleForDeleteItems,
    );

    const confirmDeleteNotRuleHtml = confirmDeleteTripDialog && !ruleForDeleteItems && this.renderDeleteDialog(
      styles['user-dialog'],
      DIALOGSTITLES.DELETE.REALLY_DELETE,
      DECISIONBUTTONS.LABELS.DELETE,
    );

    const confirmDeleteOrders = confirmDeleteOrdersDialog && (
      <Dialog onClick={ () => this.closeDialog('confirmDeleteOrdersDialog') }>
        <div className={ styles['user-dialog'] }>
          <div>{ DIALOGSTITLES.CONFIRM.ORDERS }</div>
          <div className={ `${styles.row} ${styles.right}` }>
            <DecisionButtons
              waitingResponse={ waitResDelete }
              onCancel={ () => this.closeDialog('confirmDeleteOrdersDialog') }
              onSave={ deleteOrders }
              labelSave={ DECISIONBUTTONS.LABELS.DELETE }
            />
          </div>
        </div>
      </Dialog>
    );

    const condirmDeleteActionVersion = confirmDeleteActualVersionDialog && (
      <Dialog onClick={ () => this.closeDialog('confirmDeleteActualVersionDialog') }>
        <div className={ styles['user-dialog'] }>
          <div>{ DIALOGSTITLES.CONFIRM.VERSION }</div>
          <div className={ `${styles.row} ${styles.right}` }>
            <DecisionButtons
              waitingResponse={ waitResDelete }
              onCancel={ () => this.closeDialog('confirmDeleteActualVersionDialog') }
              onSave={ deleteActualVersion }
              labelSave={ DECISIONBUTTONS.LABELS.DELETE }
              disabled={ disabledButton }
            />
          </div>
        </div>
      </Dialog>
    );

    const confirmDeleteOperations = confirmDeleteOperationsDialog && (
      <Dialog onClick={ () => this.closeDialog(DIALOGS.CONFIRM_OPERATIONS_DELETE) }>
        <div className={ styles['user-dialog'] }>
          <div>{ DIALOGSTITLES.CONFIRM.OPERATIONS }</div>
          <div>{ this.renderOperationsInf() }</div>
          <div className={ styles.attention }>{ DIALOGSTITLES.CONFIRM.ATTENTION_DOCUMENTS }</div>
          <div className={ styles.attention }>{ DIALOGSTITLES.CONFIRM.ATTENTION_CANCEL }</div>
          <div className={ `${styles.row} ${styles.right}` }>
            <DecisionButtons
              waitingResponse={ waitResDelete }
              onCancel={ () => this.closeDialog(DIALOGS.CONFIRM_OPERATIONS_DELETE) }
              onSave={ deleteOperations }
              labelSave={ DECISIONBUTTONS.LABELS.DELETE }
            />
          </div>
        </div>
      </Dialog>
    );

    return (
      <div>
        { cannotDeleteTripDialog && (
          <Dialog onClick={ () => this.closeDialog('cannotDeleteTripDialog') }>
            <div className={ styles['user-dialog'] }>
              <div>
                { DIALOGSTITLES.DELETE_NOT_POSSIBLE.TRIP }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ () => this.closeDialog('cannotDeleteTripDialog') }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { cannotDeleteOrdersDialog && (
          <Dialog onClick={ () => this.closeDialog('cannotDeleteOrdersDialog') }>
            <div className={ styles['user-dialog'] }>
              <div>
                { DIALOGSTITLES.DELETE_NOT_POSSIBLE.TRIP }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ () => this.closeDialog('cannotDeleteOrdersDialog') }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { cannotDeleteActualVersionDialog && (
          <Dialog onClick={ () => this.closeDialog('cannotDeleteActualVersionDialog') }>
            <div className={ styles['user-dialog'] }>
              <div>
                { DIALOGSTITLES.DELETE_NOT_POSSIBLE.VERSION }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ () => this.closeDialog('cannotDeleteActualVersionDialog') }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { tripDeletedDialog && (
          <Dialog onClick={ this.closeTripDeletedDialog }>
            <div className={ styles['user-dialog'] }>
              <div>
                { DIALOGSTITLES.DELETE.TRIP }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.closeTripDeletedDialog }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { ordersDeletedDialog && (
          <Dialog onClick={ this.closeOrdersDeletedDialog }>
            <div className={ styles['user-dialog'] }>
              <div>
                { DIALOGSTITLES.DELETE.ORDERS }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.closeOrdersDeletedDialog }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { actualversionDeletedDialog && (
          <Dialog onClick={ this.closeActualVersionDeletedDialog }>
            <div className={ styles['user-dialog'] }>
              <div>
                { DIALOGSTITLES.DELETE.LAST_VERSION_ORDERS }
              </div>
              <div className={ `${styles.row} ${styles.right}` }>
                <Button
                  label={ COMPONENTS.BUTTON.THEME.LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.closeActualVersionDeletedDialog }
                />
              </div>
            </div>
          </Dialog>
        ) }

        { dangerousDeleteTripHtml }

        { confirmDeleteRuleHtml }
        { confirmDeleteNotRuleHtml }

        { dangerousDeleteOrdersHtml }
        { dangerousDeleteOrdersWithIntegrationHtml }
        { dangerousDeleteOrdersOnlyIntegrationHtml }

        { confirmDeleteOrders }

        { dangerousDeleteActualVersionDialogHtml }
        { dangerousActualVersionWithIntegrationHtml }
        { dangerousActualVersionOnlyIntegrationHtml }

        { condirmDeleteActionVersion }

        { dangerousDeleteTripOnlyIntegrationHtml }
        { dangerousDeleteTripWithIntegrationHtml }

        { confirmDeleteOperations }
      </div>
    );
  }
}

export default DeleteItemDialogs;
